<footer class="">
<div class="container py-2">
    <div class="row">
        <div class="col-md-4 text-md-left">
          <p class="mb-0 copyright">{{commonService?.companyDetail?.CopyRight}} </p>
        </div>
         <div class="col-md-4">
          <!-- <div class="row col-md-12 col-sm-12"> -->
          <p class="mb-0 ">{{commonService?.Branchlist?.HotelName }}<br>
          {{commonService?.Branchlist?.Address}}<br>
         {{commonService?.Branchlist?.MobileNo }} || {{commonService?.Branchlist?.Email }}</p>
        </div>
      <!-- </div> -->
        <div class="col-md-4 text-md-right">
          <p class="mb-0">Share on:
            <a *ngIf="commonService?.Branchlist?.Facebook" href={{commonService?.Branchlist?.Facebook}} target="_blank" class="fa fa-facebook text-white"></a>
            <a *ngIf="commonService?.Branchlist?.Twitter"href={{commonService?.Branchlist?.Twitter}} target="_blank"class="fa fa-twitter text-white"></a>
            <a *ngIf="commonService?.Branchlist?.google"href={{commonService?.Branchlist?.google}} target="_blank"class="fa fa-google text-white"></a>
            <a *ngIf="commonService?.Branchlist?.Linkdin"href={{commonService?.Branchlist?.Linkdin}} target="_blank"class="fa fa-linkedin text-white"></a>
            <a *ngIf="commonService?.Branchlist?.Instagram"href={{commonService?.Branchlist?.Instagram}} target="_blank"class="fa fa-instagram text-white"></a>
            <a *ngIf="commonService?.Branchlist?.YouTube" href={{commonService?.Branchlist?.YouTube}}target="_blank" class="fa fa-youtube text-white"></a>
        </p>
        </div>
    </div>
</div>
</footer>



<header>
  <div class="hero-image" style="background-image: url({{commonService?.Branchlist?.banerimage}})">

    <div class="hero-text p-2 p-md-3">
      <div class="text-left daynight-row">
        <span (click)="changeBookingType('OverNight Stay')" class="daynght-icon"
          [ngStyle]="{'border-bottom':bookingType==='OverNight Stay'?'groove':''}"><i class="fa fa-bed"
            aria-hidden="true"></i> Over Night Stay</span>
        <span (click)="changeBookingType('Day Room')"
          [ngStyle]="{'border-bottom':bookingType==='Day Room'?'groove':''}"><i class="fa fa-sun-o"
            aria-hidden="true"></i> Day Rooms</span>
      </div>
      <form class="form-inline">
        <div class="arr-gp text-left">
          <label for="arrival" class="text-left d-block">Check-in:</label>
          <input class="form-control" type="date" id="arrival" [min]="today" (change)="departureDate($event)"
            name="arrival" [(ngModel)]="arrival">
        </div>
        <div class="dep-gp text-left">
          <label for="departure" class="text-left d-block">Check-Out:</label>
          <input [disabled]="bookingType==='Day Room'" class="form-control" type="date" id="departure"
            [min]="getNext(arrival)" (change)="bookedRoomsData=[];roomsData=[]" name="departure"
            [(ngModel)]="departure">
        </div>
        <div class="form-group1 text-left p-2 d-none">
        </div>
        <div class="ar-dp-btn text-left p-2 mt-md-4">
          <button [disabled]="!arrival || !departure" (click)="getRoomInfo()" class="btn btn-primary  ">Check
            Availability</button>
          <span class="offer-txt pt-4 nav-item dropdown" role="button" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false"> <i class="fa fa-tags" aria-hidden="true"></i>
            PROMO OFFER </span>
          <div class="dropdown-menu px-2 prmocode-input-gp" aria-labelledby="navbarDropdown">
            <form class="form-inline my-lg-0">
              <input class="" type="text" placeholder="Enter Promo Code" aria-label="Search">
              <button style="" class="promo-btn" type="submit">Search</button>
            </form>
          </div>
        </div>
      </form>
    </div>
  </div>
</header>


<div class="rooms-information">
  <div class="container py-4">
    <div class="row mb-4">
      <div class="col-md-4 left-column d-none d-lg-block">
        <div class="card shadow-none mb-3 mb-md-0 book-sum-sidebar">
          <div class="card-header">
            <h2 class="mb-0 pb-0 text-center">Booking Summary</h2>
          </div>
          <ng-container *ngIf="bookedRoomsData.length == 0">
            <div class="no-roomdiv">
              <span class="vres-stripe be-noroom"></span>
              <img src="../../assets/images/norooms.png" />
              <h3>
                No Room(s)<br />
                <small>Selected</small>
              </h3>
              <div id="lookinguser" class="user_alert"></div>
            </div>
          </ng-container>
          <ng-container *ngIf="bookedRoomsData.length > 0">
            <div class="card-body">
              <div class="sum-date">
                <span>Booking Type:</span>{{bookingType}}
              </div>
              <div class="sum-date">
                <span>Dates:</span>{{arrival}} to {{departure}}
              </div>
              <div *ngIf="bookingType==='OverNight Stay'" class="sum-date">
                <span>Night:</span>{{nights}}
              </div>
              <div class="sum-room-detail" *ngFor="let booking of bookedRoomsData; let i =index">

                <span class="sum-rm-cat">{{booking.Category}}</span> &nbsp;
                (<span class="sum-rm-name">{{booking.RoomPlan}} - {{booking.RoomTypeName}}</span>)
                <span class="sum-editbtn"><i class="fa fa-edit" (click)="editRoom(booking,i)"></i></span>
                <span class="cls-btn"><i class="fa fa-close" (click)="deleteBookingRow(booking,i)"></i></span>
                <span class="cls-btn"><i class="fa fa-editr" (click)="editRoom(booking,i)"></i></span>
                <div class="sum-rm-pax-det" *ngFor="let room of booking.occupancy; let i = index">
                  <div class="d-flex align-items-center">
                    <div class="sum-rm-bk-date mr-2"><span>Room No. {{i+1}}</span> </div>
                    <div class="sum-rm-adult-sect">
                      <div class="sum-pax-adu">
                        <i class="fa fa-male mr-1"></i>{{room.adults}}
                      </div>
                      <div class="sum-pax-adu">
                        <i class="fa fa-child mr-1"></i> {{room.childs}}
                      </div>
                      <a data-toggle="modal" data-target="#breakdown" (click)="pricePopup(booking)">
                        Price Breakdown
                      </a>
                    </div>
                  </div>

                  <div class="sum-rm-ad-rate-sec">
                    <div class="sum-rm-rent-sect">
                      <div class="sum-rm-rent-row">
                        <div class="mr-1">
                          <b>{{currencySymbol}} {{(booking.TotalRent/conversionRate).toFixed(2)}}</b><br>
                          Room Rent
                        </div>
                        <div>
                          <b>{{currencySymbol}} {{(booking.TotalTax/conversionRate).toFixed(2)}}</b><br>
                          Tax Amount
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex rm-sm-tl-price mb-0">
                <span>Total Bill</span> <span class="ml-auto">{{currencySymbol}}
                  {{(TotalBill/conversionRate).toFixed(2)}}</span>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="bookedRoomsData.length > 0">
            <div class="card-footer">
              <button class="btn btn-primary w-100 my-2 my-sm-0" (click)="submit()">
                Book Now
              </button>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-md-12 col-lg-8">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="hotel-fltter-bar bdr d-flex flex-wrap mb-3">
              <div class="hotel-info">
                <div class="dropdown">
                  <button class="btn btn-gayr dropdown-toggle py-2 rounded-0 border-0 shadow-none" type="button"
                    data-toggle="collapse" data-target="#filter-opt-action">
                    <i class="fa fa-filter"></i> Filter Your Search
                  </button>
                  <div class="collapse" id="filter-opt-action">
                    <h6 class="d-flex py-2 mb-0">
                      Filter <span class="ml-auto"></span>
                      <span> <a class="ml-auto dropdown-toggle" data-toggle="collapse" data-target="#filter-opt-action">
                          <i class="fa fa-close" aria-hidden="true"></i></a></span>
                    </h6>
                    <div>
                      <h6 class="d-flex bdr-bttom py-2 mb-2">
                        Category
                      </h6>
                      <div class="form-group pt-2 mb-2">
                        <ng-select class="form-control form-control-sm cat-sel" name="category"
                          (change)="categorySelect($event)">
                          <ng-option *ngFor="let opt of categoryArray " [value]="opt.Id">{{opt.Name}}</ng-option>

                        </ng-select>
                      </div>
                    </div>
                    <div>
                      <h6 class="d-flex bdr-bttom py-2 mb-2">
                        Plan
                      </h6>
                      <div class="form-group pt-2 mb-2">
                        <ng-select class="form-control form-control-sm" name="plan" (change)="planSelect($event)">
                          <ng-option *ngFor="let opt of planArray " [value]="opt.Id">{{opt.Name}}</ng-option>

                        </ng-select>
                      </div>
                    </div>
                    <div class="display">
                      <h6 class="d-flex bdr-bttom py-2 mb-2">
                        Amount
                      </h6>
                      <div class="row">
                        <div class="col">
                          <span>
                            <label>From</label>
                            <input min="0" type=number [(ngModel)]="fromAmountSearch" placeholder="From Amount"
                              class="form-control form-control-sm w-100">
                          </span>
                        </div>
                        <div class="col">
                          <span>
                            <label>To</label>
                            <input min="0" type=number [(ngModel)]="toAmountSearch" placeholder="To Amount"
                              class="form-control form-control-sm w-100">
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="display pt-2">
                      <h6 class="d-flex bdr-bttom py-2 mb-0">
                        Rooms
                      </h6>
                      <div class="form-group pt-2">
                        <input type=number [(ngModel)]="searchedNoOfRooms" placeholder="No of Rooms"
                          class="form-control form-control-sm">
                      </div>
                    </div>

                    <div class="d-flex text-center justify-content-center mt-3">
                      <button class="btn btn-primary" (click)="search()">Apply</button>
                      <button class="btn btn-primary ml-3" type="reset" (click)="reset()">Reset</button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="reserce_details  px-2 p-2">
                Show Price : <strong>Price For Whole Stay</strong>
              </div>
            </div>
          </div>
        </div>

        <div class="row bdr mb-4 bg-gray" *ngFor="let room of roomsData; let i = index">
          <div class="col-sm-4 py-3 d-md-n d-md-block">
            <div class="room-img">
              <img src={{room.ImageList[0].RoomPath}} class="img-fluid w-100" alt="" (click)="openPopup(room)"
                data-toggle="modal" data-target="#room_Info" />
              <div class="img-holder">{{ room.RoomName }}</div>
            </div>
          </div>
          <div #planDetail class="col-sm-12 col-md-8 pt-3">
            <ng-container *ngFor="let roomplan of room.RoomPlan; let j = index;">
              <div tabindex="1" class="mb-3"
                *ngFor="let roomItem of roomplan.roomPriceListToShowInFront; let k = index;">
                <div class="card rm-info-card">
                  <div class="card-body p-2">

                    <h5 class="title">{{ roomplan.Name }} <span> - </span> {{roomItem?.RoomTypeName}} </h5>
                    <div class="row room-detail">
                      <div class="col-6">
                        <p class="mb-1">
                          <span class="pr-2">Maximum Occupancy</span>
                          <span class="pax-v-rng">
                            <span style="font-size:small">
                              <a style="color: black" data-toggle="tooltip" data-placement="top"
                                title="Max {{ roomItem.Adult }} Adults"> {{ roomItem.Adult }}&nbsp;</a>
                            </span>
                            <i class="fa fa-male mr-2" aria-hidden="true"></i>
                            <span style="font-size:small">
                              <a style="color: black" data-toggle="tooltip" data-placement="top"
                                title="Max {{ room.MaxChilds }} Childs"> {{ room.MaxChilds }}</a>
                            </span>
                            <i class="fa fa-child" aria-hidden="true"></i>
                          </span>

                        </p>
                        <p class="mb-1">Tax excluded in room price</p>
                        <p class="text-success">{{ roomplan.PlanDetails }}</p>
                      </div>
                      <div class="col-6 text-md-right">
                        <div>
                          <p class="price mb-0">{{currencySymbol}} {{ (roomItem.RoomPrice/conversionRate).toFixed(2)}}
                          </p>
                        </div>
                        <p class="mb-0">
                          <span>
                            Price for {{nights}} Nights {{ roomItem.Adult }} Adults ,
                            {{ room.MaxChilds }} Children, 1 Room
                          </span>
                        </p>
                        <div class="mb-0 d-none">
                          <div class="form-check d-none">
                            <label class="form-check-label">
                              <input type="checkbox" class="form-check-input" value="" />
                              Click To Compare
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-footer p-1">
                    <div class="rm-add-opt">
                      <a (click)="openPopup(room)" data-toggle="modal" data-target="#room_Info" class="rm-info">Room
                        Info</a>
                      <a><span class="offer">
                          <i class="fa fa-tags" aria-hidden="true"></i> PROMO
                          OFFER
                        </span>
                      </a>
                      <div class="rm-add-opt-btn-row">
                        <div class="align-items-center d-flex">
                          <span class="pr-1 rm-avial-info">{{room.AvlQty===0?room.AvlQty+' Room':room.AvlQty+' Rooms'}}
                            Available </span>
                          <ng-container *ngIf="roomItem.bookedQty" class="col-auto my-1 d-flex">
                            <span><button (click)="removeRoom(room,roomplan, roomItem,j,k)" class="btn-ad-minus">
                                <i class="fa fa-minus"></i></button></span>
                            <span class="px-2">{{roomItem.bookedQty}}</span>
                            <span><button (click)="addRoom(room, roomplan, roomItem,j,k)" class="btn-ad-minus">
                                <i class="fa fa-plus"></i></button></span>
                          </ng-container>
                          <ng-container *ngIf="!checkIsRoomAddedToBookedRoom(room,roomplan, roomItem)" class="col-auto">
                            <button
                              [disabled]='room.AvlQty === 0 || roomplan.Dates[0].RoomPrice===0 || roomItem.StopSell'
                              (click)="addRoom(room,roomplan, roomItem,j,k)" class="btn btn-primary ml-2">
                              Add room
                            </button>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <ng-container *ngFor="let count of transformCount(roomItem.bookedQty); let i = index;">
                    <ng-container class="vres-add-div d-flex mt-3">
                      <div class="room-select-pax-det">
                        <h5 class="mr-3">
                          Room
                          <span id="rm_cnt_lbl_1_0" class="lblrm">{{ i + 1}}</span>
                        </h5>
                        <div class="rm-pax-age mr-3">
                          <label class="mr-2"> Adult<br> <span>(12+ yrs)</span> </label>
                          <select id="rm_adult_1_0" class="adult" (change)="adultSelect($event,room,roomplan,count)">
                            <option *ngFor="let opt of transformCount(roomItem.Adult);let first = first "
                              [value]="opt+1" [selected]="first">{{opt+1}}</option>
                          </select>
                        </div>
                        <div class="rm-pax-age">
                          <label class="mr-2"> Child<br> <span>{{room.ShortDescription}}</span> </label>
                          <select id="rm_child_1_0" class="adult" (change)="childSelect($event,room,roomplan,count);">
                            <option selected="selected" value="0">0</option>
                            <option *ngFor="let opt of transformCount(room.MaxChilds);let first = first "
                              [value]="opt+1">{{opt+1}}</option>
                          </select>
                        </div>
                        <ng-container *ngFor="let age of bookedRoomsData">
                          <ng-container *ngIf="age.RoomId === room.RoomId && age.RoomPlan === roomplan.Name ">
                            <div class="rm-pax-age-child"
                              *ngFor="let ageIndex of transformCount(age.occupancy[count]?.childs)">
                              <label class="">Age of Child <br></label>
                              <select (change)="ageSelect($event,room,roomplan,count,ageIndex);">
                                <option [value]="1">1</option>
                                <option [value]="2">2</option>
                                <option [value]="3">3</option>
                                <option [value]="4">4</option>
                                <option [value]="5">5</option>
                                <option [value]="6">6</option>
                                <option [value]="7">7</option>
                                <option [value]="8">8</option>
                                <option [value]="9">9</option>
                                <option [value]="10">10</option>
                                <option [value]="11">11</option>
                                <option [value]="12">12</option>
                              </select>
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </ng-container>
                  </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="container-fulid">
<div id="demo1" class="carousel slide" data-ride="carousel" data-interval="500" *ngIf="commonService?.ImageListApp && commonService?.ImageListApp.length>0">
  <!-- The slideshow -->
   <div class="carousel-inner home_room_slider">
    <div *ngFor="let image of commonService?.ImageListApp; let i = index" class="carousel-item"
      [ngClass]="{'active': i === 0 }">
      <img src={{image.ImagePath}} class="img-fluid" />
    </div>
  </div>
  <!-- Left and right controls -->
   <a class="carousel-control-prev" href="#demo1" data-slide="prev">
    <span class="carousel-control-prev-icon"></span>
  </a>
  <a class="carousel-control-next" href="#demo1" data-slide="next" id="nextSliderHomeFooter">
    <span class="carousel-control-next-icon"></span>
  </a>
</div> 
</section>
<div class="modal fade" id="myModal-1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content hotel-info-model">
      <div class="modal-header">
        <h4 class="modal-title">Compare Rooms</h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body">Compare Rooms</div>
    </div>
  </div>
</div>
<div *ngIf="modelToShow" class="modal fade" id="room_Info">
  <div class="modal-dialog modal-lg">
    <div class="modal-content hotel-info-model">
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">
          {{ RoomInfo.RoomName }}
        </h4>
        <button type="button" class="close" data-dismiss="modal">
          &times;
        </button>
      </div>
      <!-- Modal body -->
      <div class="modal-body">
        <div class="container">
          <!-- Nav tabs -->

          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home2" role="tab" aria-controls="home"
                aria-selected="true">Room Details</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="profile-tab" (click)="clickNextSlider()" data-toggle="tab" href="#profile"
                role="tab" aria-controls="profile" aria-selected="false">Photo Gallery</a>
            </li>
            <li class="nav-item" role="presentation">
              <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact"
                aria-selected="false">Make An Enquiry</a>
            </li>

          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home2" role="tabpanel" aria-labelledby="home-tab">
              <div class="roomdetail mt-3">
                <p><b>Room Category :</b> {{RoomInfo.RoomName}}</p>
                <p><b>Detail :</b> {{RoomInfo.Detail}}</p>
                <p> <b>Room View Type :</b> {{RoomInfo.ViewType}}</p>
                <p> <b>Room Size :</b> {{RoomInfo.RoomSize}}</p>
                <p>
                  <b>Bed Type :</b> {{RoomInfo.BedSize}}
                </p>
                <p> <b>Special Condition :</b> {{RoomInfo.SpecialConditions}}</p>
                <p></p>
              </div>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">

              <div class="slider-div d-none">
                <div id="carouselExampleControls-pop" class="carousel slide" data-ride="carousel" data-interval="500">
                  <div class="carousel-inner">
                    <!-- <div *ngFor=" let image of RoomInfo.ImageList ; let i = index" class="carousel-item" [ngClass]="{'active': i === 0 }">
                        <img src={{image.RoomPath}} />
                      </div> -->
                  </div>
                  <a class="carousel-control-prev" href="#carouselExampleControls-pop" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                  </a>
                  <a class="carousel-control-next" href="#carouselExampleControls-pop" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                  </a>
                </div>
              </div>


              <!-- New-Slider-Code -->
              <div id="demo" class="carousel slide" data-ride="carousel" data-interval="500">

                <!-- Indicators -->
                <ul class="carousel-indicators d-none">
                  <li data-target="#demo" data-slide-to="0" class="active"></li>
                  <li data-target="#demo" data-slide-to="1"></li>
                  <li data-target="#demo" data-slide-to="2"></li>
                </ul>

                <!-- The slideshow -->
                <div class="carousel-inner home_room_slider">
                  <div *ngFor=" let image of RoomInfo.ImageList ; let i = index" class="carousel-item photo-gallery"
                    [ngClass]="{'active': i === 0 }">
                    <img src={{image.RoomPath}} />
                  </div>
                </div>

                <!-- Left and right controls -->
                <a class="carousel-control-prev" href="#demo" data-slide="prev">
                  <span class="carousel-control-prev-icon"></span>
                </a>
                <a class="carousel-control-next" href="#demo" data-slide="next" id="nextSliderForPhotoGallery">
                  <span class="carousel-control-next-icon"></span>
                </a>

              </div>


            </div>
            <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
              <div class="vres-tab-cnt " data-tab="enqu">
                <div class="enqire_temp">
                  <div id="enquiryform_done" style="display:none;">
                    <h2 class="subheading themefont padtop" style="font-size:15px; border:none; text-align:center;">
                      Thank you for your message.<br>Your message has been successfully sent. <br>We will get back to
                      you if there is any room available you have requested for. <span id="dis_to1"></span></h2>
                  </div>
                  <div class="en_form" id="enquiryform">
                    <span id="spldesc" style="display:none;">The spacious accommodation will provide you with air
                      conditioning and a balcony. There is also an electric kettle. Featuring a shower, private bathroom
                      also comes with a bath and a hairdryer.</span>
                    <span id="specialname" style="display:none;">Stay for 2 nights and get exclusive 20% discount</span>
                    <input type="hidden" name="enquiryfor" id="enquiryfor"
                      value="Stay for 2 nights and get exclusive 20% discount">
                    <textarea rows="4" cols="10" class="txtarea" name="enquirydetails" id="enquirydetails"
                      style="display:none;">The spacious accommodation will provide you with air conditioning and a balcony. There is also an electric kettle. Featuring a shower, private bathroom also comes with a bath and a hairdryer.</textarea>
                    <div id="enquiryform_error" style="display:none;margin-bottom: 10px; margin-top: -5px;"
                      class="common_error">
                      <span class="subheading themefont padtop"
                        style="font-size:14px; border:none; text-align:center;">Sorry , there is an error while sending
                        an email.</span>
                    </div>
                    <div id="enquiryinsert_error" style="display:none;margin-bottom: 10px; margin-top: -5px;"
                      class="common_error">
                      <span class="subheading themefont padtop"
                        style="font-size:14px; border:none; text-align:center;">Sorry , there is an error while
                        requesting for an enquiry.</span>
                    </div>
                    <div id="enquiryempty_error"
                      style="display:none;margin-bottom: 10px; margin-top: -5px;padding: 5px;" class="common_error">
                      <span class="subheading themefont padtop"
                        style="font-size:14px; border:none; text-align:center;">Sorry , some informations are
                        missing.</span>
                    </div>
                    <div class="valerror common_error" id="makeenquiryerror"
                      style="display:none; margin-bottom:10px;  margin-top: -5px;"> <span></span><br clear="all"></div>

                    <div id="captchaerror" style="display:none;margin-bottom: 10px; margin-top: -5px;padding: 5px;"
                      class="common_error">
                      <span class="subheading themefont padtop"
                        style="font-size:14px; border:none; text-align:center;">Error ! Invalid captcha code.</span>
                    </div>
                    <form class="vres-form" id="enquire_form" onsubmit="validateenquiryform();return false;">

                      <div class="enq-left pad-right">
                        <div class="form-wrap">
                          <label for="encname" class="sr-only-k form-lbl">Name</label>
                          <input type="text" name="encname" id="encname" [(ngModel)]="PartyName" required
                            [ngClass]="{'error': submitClick &&  !PartyName}">
                          <span class="mandatory">*</span>
                        </div>
                        <div class="form-wrap">
                          <label for="phone" class="sr-only-k form-lbl">Phone</label>
                          <input name="phone" id="phone" type="text" [(ngModel)]="Phone" required
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            [ngClass]="{'error': submitClick &&  !Phone}">
                          <span class="mandatory">*</span>
                        </div>
                        <div class="form-wrap">
                          <label for="encemail" class="sr-only-k form-lbl">Email</label>
                          <input name="encemail" id="encemail" type="text" [(ngModel)]="Email" required
                            [ngClass]="{'error': submitClick &&  !Email}">

                          <span class="mandatory">*</span>
                        </div>
                        <div class="vres-checkavail" id="checkavaildatepcker1">
                          <div class="input-daterange">
                            <label class="vres-date" for="chk_in">
                              <div class="input-group date">
                                <!-- <span class="input-group-addon"><i class="fa fa-calendar"></i></span> -->
                                <label class="sr-only-k form-lbl">Form</label>
                                <input class="form-control" type="date" id="ToDate" [min]="today"
                                  (change)="departureDate1($event)" name="ToDate" [(ngModel)]="ToDate">

                              </div>
                            </label>
                            <label class="vres-date" for="chk_out">
                              <div class="input-group date">
                                <!-- <span class="input-group-addon"><i class="fa fa-calendar"></i></span> -->
                                <label class="sr-only-k form-lbl">To</label>
                                <input class="form-control" type="date" id="FromDate" [min]="getNext(ToDate)"
                                  name="FromDate" [(ngModel)]="FromDate">

                              </div>
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="enq-left pad-left">
                        <div class="form-wrap">

                          <label class="vres-select">
                            <label class="form-lbl">Adult(s)</label>
                            <select id="eq_noofadult" name="eq_noofadult" [(ngModel)]="Adults" required
                              [ngClass]="{'error': submitClick &&  !PartyName}">
                              <option value="A">Adult(s)</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              <option>6</option>
                              <option>7</option>
                              <option>8</option>
                              <option>9</option>
                              <option>10</option>
                            </select>
                            <i class="fa fa-caret-down"></i>
                            <span class="mandatory">*</span>
                          </label>
                        </div>
                        <div class="form-wrap">
                          <label class="vres-select">
                            <label class="form-lbl">Child</label>
                            <select id="eq_noofchild" name="eq_noofchild" [(ngModel)]="Child" required
                              [ngClass]="{'error': submitClick &&  !PartyName}">
                              <option value="">Child</option>
                              <option>0</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              <option>6</option>
                              <option>7</option>
                              <option>8</option>
                              <option>9</option>
                              <option>10</option>
                            </select>
                            <i class="fa fa-caret-down"></i>
                            <span class="mandatory">*</span>
                          </label>
                        </div>
                        <div class="form-wrap">
                          <label class="vres-select">
                            <label class="form-lbl">Rooms</label>
                            <select id="eq_noofrooms" name="eq_noofrooms" [(ngModel)]="Rooms" required
                              [ngClass]="{'error': submitClick &&  !PartyName}">
                              <option value="">Room(s)</option>
                              <option>1</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                              <option>6</option>
                              <option>7</option>
                              <option>8</option>
                              <option>9</option>
                              <option>10</option>
                            </select>
                            <i class="fa fa-caret-down"></i>
                            <span class="mandatory">*</span>
                          </label>
                        </div>
                        <div class="form-wrap pt-3">
                          <label class="form-lbl">Message</label>
                          <textarea name="encmessage" id="encmessage" rows="4" [(ngModel)]="Message"></textarea>

                        </div>
                      </div>

                      <div class="enq-full pt-2">
                        <input type="submit" value="Enquire" id="sendenquiry" class="enquiry-btn-full"
                          (click)="submiten()">
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="pricePopupToShow" class="modal  right fade" id="breakdown">
  <div class="modal-dialog  model-full-height model-right hotel-info-model">
    <!-- Modal Header -->
    <div class="modal-header">
      <h4 class="modal-title">
        Price Breakdown
      </h4>
      <button type="button" class="close" data-dismiss="modal">
        &times;
      </button>
    </div>
    <!-- Modal body -->
    <div class="modal-body" style="padding: 0px;">

      <div class="sum-rm-ad-rate-sec">
        <div class="sum-rm-rent-sect">
          <div class="sum-rm-rent-sect" *ngFor="let price of priceData.Price[0]">
            <div class="sum-rm-rent-row">
              <div class="sum-rm-rent-date mr-1">{{price.BookingDate}}<br>
                {{price.NextDate}}
              </div>
              <div class="mr-1">
                <b>{{currencySymbol}} {{(price.RoomRent/conversionRate).toFixed(2)}}</b><br>
                Room Rent
              </div>
              <div>
                <b>{{currencySymbol}} {{(price.TaxAmount/conversionRate).toFixed(2)}}</b><br>
                Tax Amount
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="bookedRoomsData.length > 0" class="room-sum-det-mb">
  <div class="px-2 d-flex align-items-center"><img src="../../assets/images/info.png" /></div>
  <div class="room-sum-det-mb-info p-2">
    <p class="rm-sum-m-val mb-0">{{currencySymbol}} {{TotalBill}}</p>
    <p class="mb-0"><span class="sky-txt pr-2">{{totalbookedRoom}} Room Added</span> {{arrival}} - {{departure}}</p>
  </div>
  <a (click)="submit()" class="rm-sum-btm-mb ml-auto">Book</a>
</div>